*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*colors*/
  --ash: #707070;
  --blue: #2699fb;
  --fb-blue: #3a5794;
  --insta-red: red;
  --linkedIn-blue: #0086c2;
  --black: #190f12;
  --white: #ffffff;
  --light-grey: #ededed;
  --whatsapp-green: #25D366;
  --red: #ed1c24;

  --container-width: 1200px;
  --v-whitespace: 5em;
  --font-size: 16px;
  --header-h: 70px;
  --transition-speed: 0.25s;

  --light-inside-shadow: inset 3px 5px 6px rgba(0, 0, 0, 0.2);
  --light-outside-shadow: 3px 5px 6px rgba(0, 0, 0, 0.2);

  background-color: var(--light-grey);
  font-size: var(--font-size);
}

* {
  line-height: 200%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSvgIcon-root {
  pointer-events: none !important;
}

.BgPrimary {
  background-color: var(--blue);
}

.ViewHeading {
  color: var(--blue);
  text-transform: uppercase;
  text-align: center;

  position: relative;
  margin-bottom: 0.5em;
}

.ViewHeading::before {
  content: "";

  position: absolute;
  top: calc(100% + 0.2em);
  left: 50%;
  transform: translateX(-50%);

  height: 5px;
  width: 60px;
  border-radius: 3em;
  background-color: var(--blue);
}
