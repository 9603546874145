.AppFooter {
  padding: 3em 0;
  color: var(--white);
}

.AppFooter__Link {
  text-decoration: none;
  color: inherit;
  text-transform: capitalize;

  display: block;
  padding: 0.3em 0;
}

.AppFooter__Link:hover {
  opacity: 0.86;
  text-decoration: underline;
}

.FooterHeading {
  display: block;
  text-transform: uppercase;
  font-size: calc(var(--font-size) + 2px);
  margin-bottom: 0.5em;
}

.AppFooter__Row {
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content:space-between;
}

.AppFooter__CompanyName {
  text-align: center;
  margin-top: 2em;
  font-size: calc(var(--font-size) - 2px);
}

.AppFooter__EmailInput {
  border: none;
  margin-top: 2em;
  padding: 0.7em 1em;
  width: 100%;
  font-size: calc(var(--font-size) + 2px);
  border-radius: 5px;
}

.AppFooter__EmailInput:focus {
  outline: none;
  box-shadow: var(--light-inside-shadow);
}

.AppFooter__SubscribeBtn {
  all: initial;
  font-family: inherit;
  font-weight: 450;
  background-color: var(--white);

  display: block;
  margin-left: auto;
  padding: 0.5rem 2rem;
  margin-top: 0.5em;
  border-radius: 5px;

  transition: 0.25s;
}

.AppFooter__SubscribeBtn:hover {
  cursor: pointer;
  box-shadow: var(--light-outside-shadow);
}
