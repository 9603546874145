.Contact__MainContent {
	padding: var(--v-whitespace) 0;
}

.Contact__Col--Right {
	margin-top: 2rem;
}

.Contact__Address,
.Contact__Info {
	background-color: var(--white);
	border-radius: 1rem;
	padding: 1.5rem;
}

.Contact__Col__Heading {
	font-size: 1.3rem;
	color: var(--ash);
}

.Contact__Col__Heading:not(:first-child) {
	margin-top: 1rem;
}

.Contact__Info__Text {
	margin-top: 1rem;
	line-height: 150%;
	font-size: 0.95rem;
}

.Contact__Info {
	margin-top: 2rem;
}

.Contact__Address a {
	text-decoration: none;
	color: var(--blue);
	font-weight: 700;
}

.Contact__Address a:hover {
	text-decoration: underline;
}

.SocialMedia {
	display: grid;
	/*grid-gap: 1rem;*/
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	margin-top: 1rem;
}

.SocialMedium {
	display: flex;
	align-items: center;
	padding: 0.5rem;

	text-decoration: none;
	position: relative;
	overflow: hidden;
}

[data-text="Twitter"]::after,
[data-text="Twitter"] {
	color: var(--blue) !important;
}

[data-text="Instagram"]::after,
[data-text="Instagram"] {
	color: var(--insta-red) !important;
}

[data-text="LinkedIn"]::after,
[data-text="LinkedIn"] {
	color: var(--linkedIn-blue) !important;
}
[data-text="Facebook"]::after,
[data-text="Facebook"] {
	color: var(--fb-blue) !important;
}
[data-text="WhatsApp"]::after,
[data-text="WhatsApp"] {
	color: var(--whatsapp-green) !important;
}

.SocialMedium::before, .SocialMedium::after {
	content: attr(data-text);
	width: 100%;
	height: 100%;
	padding-left: 2.5rem;
	color: var(--black);

	position: absolute;
	left: 0;
	top: 0;


	display: flex;
	align-items: center;

	transition: var(--transition-speed);
	z-index: 1;
}
.SocialMedium::after {
	top: 100%;
	font-weight: 600;
	z-index: 3;
}



.SocialMedium:hover::before { top: -100% }
.SocialMedium:hover::after { top: 0 }

@media (min-width: 768px) {
	.Contact__MainContent {
		display: grid;
		grid-template-columns: 3fr 2fr;
		grid-column-gap: 2rem;
	}

	.Contact__Col--Right {
		margin-left: 2rem;
		margin-top: 0;
	}
}