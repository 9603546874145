.TeamCard {
	text-align: center;
	background-color: var(--white);
	transition: calc(var(--transition-speed) - 500ms);
	border-radius: 5px;
	padding: 1.5rem;
	padding-bottom: 2rem;

	position: relative;
	overflow: hidden;
}

.TeamCard::before {
	content: "";

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	width: 100%;
	height: 90px;
	border-radius: 0 0 50% 50%;
	background-color: var(--blue);

	transition: var(--transition-speed);
}

.TeamCard:hover {
	box-shadow: var(--light-outside-shadow);
}

.TeamCard--Img {
	width: 180px;
	height: 180px;
	margin-bottom: 0.5rem;
	border-radius: 50%;
	border: 5px solid var(--white);
	object-fit: cover;

	position: relative;
	z-index: 2;
	background-color: var(--white);
}

@media (min-width: 576px) {
	.TeamCard::before {
		top: -100%;
	}

	.TeamCard:hover::before {
		top: 0;
	}
}
