.Services__Row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 3rem 2em;

	margin-top: 3em;
}

.Services__Service {
	text-align: center;
}

.ServiceHeading {
	color: var(--black);
	font-size: calc(var(--font-size) * 1.4);
	margin-top: 1rem;
}

.ServiceDescription {
	color: var(--ash);
	margin-top: 0.6em;
	line-height: 140%;
}


.Service__Img {
	width: auto;
	height: 100px;
	min-height: 8rem;
}