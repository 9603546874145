.Header {
	height: var(--header-h);
	background-color: var(--white);

	display: flex;
	align-items: center;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100vw;
}

.Header--Shadow {
	box-shadow: var(--light-outside-shadow);
}

.Header__Container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Header__Logo {
	height: auto;
	width: 45px;
	margin-right: auto;
}

.Header__Navbar {
	position: absolute;
	top: 100%;
	left: 0;

	width: 100%;
	height: 0;
	overflow: hidden;
	transition: var(--transition-speed) ease-in-out;

	background-color: var(--white);
	box-shadow: var(--light-outside-shadow);
}

.Header__Navbar--Show {
	height: 230px;
	transition: var(--transition-speed) ease-in-out;
}

.Header__NavItem {
	display: block;
	width: 100%;
	padding: 0.7em 1em;

	color: var(--blue);
	text-decoration: none;
}

.Header__NavItem:hover {
	/* background: var(--light-grey); */
	color: var(--red);
}

.Header__NavItem--Active {
	/* background-color: var(--blue) !important; */
	border-radius: 0px;
	border-bottom: 2px solid var(--red);
	color: var(--red);
}

.NavbarToggler svg {
	font-size: calc(var(--font-size) * 1.7) !important;
}

.Header__NavItem--Active:hover {
	opacity: 0.9
}

@media (min-width: 576px) {
	.Header__Navbar {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		box-shadow: none;

		position: static;
	}

	.Header__Navbar {
		height: auto !important;
	}

	.NavbarToggler {
		display: none !important;
	}

	.Header__NavItem {
		width: auto;
		padding: 0.5em 1em;
		/* border-radius: 5px; */
	}

	.Header__NavItem + .Header__NavItem {
		margin-left: 0.5em;
	}
}