.FilterBar {
	width: 100%;
	white-space: nowrap;
	overflow-x: auto;
	margin: 2rem 0 !important;

	text-align: center;
}

.FilterButton {
	text-transform: capitalize;
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
}

.FilterButton + .FilterButton {
	margin-left: 0.5rem;
}

.FilterButton--NotActive {
	background-color: transparent;
	color: var(--black);
	border-color: transparent;
}