.BaseButton {
	all: initial;
	background-color: var(--blue);
	border-radius: 5em;
	border: 1px solid var(--blue);

	padding: 0.5em 1.5em;
	font-family: inherit;
	color: var(--white);

	transition: all var(--transition-speed);
}

.BaseButton:disabled {
	background-color: var(--light-grey);
	border-color: var(--light-grey);
	color: var(--ash);
}

.BaseButton--Large {
	padding: 0.7em 2.5em;
}

.BaseButton:focus {
	outline: none;
}

@media (min-width: 576px) {
	.BaseButton:hover {
		color: var(--blue);
		background-color: transparent;
	}
}